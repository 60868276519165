import { pick } from 'lodash-es';
import analytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { hashSha256 } from 'utils/helpers';
import { Order } from 'utils/types';
import { trackJustUnoConversion } from 'utils/vendor/justuno';
import { v4 as uuidv4 } from 'uuid';

import { freshpaint } from './freshpaint';
import { gtm } from './gtm';
import { vwo } from './vwo';

export type Product = {
  id: number;
  is_subscription: boolean;
  name: string;
  price: number;
  quantity: number;
  subTotal: number;
  subscription_frequency: number | string | null;
  variant: string;
};

type ConversionPayload = {
  adjustment: string;
  discountAmount: string;
  eventId: string;
  orderEmail: string;
  phone?: string;
  orderId: string;
  orderPk: number;
  orderTotal: string;
  products: Product[];
  userId: number;

  // Optional Attributes
  addressAddress1?: string;
  addressCity?: string;
  addressState?: string;
  addressZipCode?: string;
  cJEventId?: null | string;
  promoCode?: string;
};

type AddItemToCartPayload = {
  currencyCode: 'USD';
  eventId: string;
  productId: number;
  productName: string;
  productPrice: number | string;
  productQuantity: number;
  productSubscription: string;
  productVariantId: number;
};

type CheckoutPayload = {
  orderTotal: string;
  orderEmail: string;
  products: Product[];
  userId: number;
};

type PurchasePayload = {
  eventId: string;
  orderId: number;
  orderTotal: string;
  orderPromoCode?: string;
  discountAmount: string;
  phone?: string;
  products: Product[];
  userEmail: string;
  userId: number;
};

type PDPPageViewPayload = {
  eventId: string;
  productId: number;
  productName: string;
  productPrice: number;
};

type MembershipPurchasePayload = {
  program: string;
  userEmail: string;
  userId: string;
};

const MEMBERSHIP_TRACKING_SLUGS: Record<string, string> = {
  'everlywell-plus': 'EW Plus',
  'weight-care-plus': 'Weight Care',
};

export namespace tracker {
  export const trackAddItemToCart = (
    payload: AddItemToCartPayload,
    order: Order,
  ) => {
    // NOTE: needed by GA4
    const products = [
      {
        id: payload.productVariantId,
        name: payload.productName,
        price: payload.productPrice,
        is_subscription: payload.productSubscription,
        brand: '',
        category: '',
        variant: '',
        quantity: payload.productQuantity,
      },
    ];

    let existingLineItems: {
      id: number;
      name: string;
      price: string;
      quantity: number;
      is_subscription?: boolean;
      brand?: string;
      category?: string;
      variant?: string;
      sku?: string;
    }[] = [];

    if (order?.line_items && order.line_items.length > 0) {
      order.line_items.forEach((lineItem) => {
        existingLineItems.push({
          id: lineItem.variant_id!,
          name: lineItem.product_name!,
          price: lineItem.total!,
          quantity: lineItem.quantity,
          is_subscription: lineItem.is_subscription,
          sku: lineItem.product_sku,
          brand: '',
          category: '',
          variant: '',
        });
      });
    }

    gtm.track({
      event: 'addToCart',
      eventId: payload.eventId,
      ecommerce: {
        currencyCode: payload.currencyCode,
        add: { products },
        cart: {
          items: existingLineItems,
        },
      },
    });

    freshpaint.track('Add Item to Cart', {
      ...payload,
      products,
      insert_id: payload.eventId, // need to match the eventId in GTM for deduplication
    });

    existingLineItems = [];
  };

  export const trackConversion = (payload: ConversionPayload) => {
    // Check if the conversion for this order has already been tracked. This is to avoid duplicate tracking on Confirmation page refresh
    if (
      !sessionStorage.getItem('conversion-tracked') ||
      sessionStorage.getItem('conversion-tracked') !== payload.orderId
    ) {
      gtm.track({
        event: 'conversion',
        eventId: payload.eventId,
        cJEventId: payload.cJEventId,
        conversion: pick(payload, [
          'adjustment',
          'discountAmount',
          'orderEmail',
          'orderId',
          'orderPk',
          'orderTotal',
          'products',
          'userId',
        ]),
        address: {
          state: payload.addressState,
          city: payload.addressCity,
          address_1: payload.addressAddress1,
          zip_code: payload.addressZipCode,
        },
      });

      freshpaint.track('Conversion', {
        ...payload,
        productIds: payload.products.map((product) => product.id),
        email: payload.orderEmail,
        phone: payload.phone ?? '',
        insert_id: payload.eventId,
      });

      // Store the orderId in sessionStorage to prevent firing again
      sessionStorage.setItem('conversion-tracked', payload.orderId);
    }
  };

  export const trackCheckout = (payload: CheckoutPayload) => {
    gtm.track({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: {
            // this step refers to a point in the checkout funnel
            step: 2,
          },
          products: payload.products,
        },
      },
      userEmail: payload.orderEmail,
      userId: payload.userId,
    });

    freshpaint.track('Checkout', payload);
  };

  export const trackPurchase = (payload: PurchasePayload) => {
    const hashedEmail = hashSha256(payload.userEmail) || null;

    gtm.track({
      event: 'purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: payload.orderId,
            coupon: payload.orderPromoCode ?? '',
            discount: parseFloat(payload.discountAmount),
            revenue: parseFloat(payload.orderTotal),
          },
          products: payload.products,
        },
      },
      userEmail: payload.userEmail,
      userId: payload.userId,
      eventId: payload.eventId,
    });

    freshpaint.track('Purchase', {
      ...payload,
      productIds: payload.products.map((product) => product.id),
      hashedEmail,
      insert_id: payload.eventId,
      action_source: 'website',
      email: payload.userEmail,
      phone: payload.phone ?? '',
    });

    vwo.trackRevenueConversion(parseFloat(payload.orderTotal));
  };

  export type TrackEntirePurchasePayload = {
    order: Order;
    marketingData: {
      emailOptIn: boolean;
      firstName: string;
      lastName: string;
      phone: string;
      email: string;
      state: string;
      city: string;
    };
    shippingInfo?: {
      id: string;
      name: string;
      fee: number;
    };
    paymentMethod: string;
  };

  export const trackEntirePurchase = (payload: TrackEntirePurchasePayload) => {
    const { order, marketingData, shippingInfo } = payload;

    trackPurchase({
      eventId: `purchase.${order.id.toString()}`,
      discountAmount: order.promo_total,
      orderId: order.id,
      orderPromoCode: order.promotions?.[0]?.code ?? '',
      orderTotal: order.total,
      phone: marketingData.phone ?? '',
      products: order.line_items.map((lineItem) => ({
        name: lineItem.product_name,
        id: lineItem.product_id,
        price: parseFloat(lineItem.base_price ?? '0'),
        variant: lineItem.variant_sku,
        quantity: lineItem.quantity,
        subTotal: lineItem.quantity * parseFloat(lineItem.base_price ?? '0'),
        is_subscription: lineItem.is_subscription,
        subscription_frequency: lineItem.subscription_frequency,
      })) as any,
      userEmail: (order.email as string) || (marketingData.email as string),
      userId: order.user_id as unknown as number,
    });

    gtm.track({
      event: 'cartCheckout',
      marketing: {
        attributes: {
          phone: marketingData.phone,
          state: marketingData.state,
          city: marketingData.city,
          first_name: marketingData.firstName,
          last_name: marketingData.lastName,
          email: marketingData.email,
        },
      },
    });

    gtm.track({
      event: 'marketing',
      marketing: {
        receiveMarketing: marketingData.emailOptIn,
      },
    });
    trackJustUnoConversion(order);

    analytics.track({
      event: ANALYTICS.EVENTS.PURCHASE,
      data: {
        id: order.id,
        revenue: parseFloat(order.total),
        coupon: order.promotions?.[0]?.code,
        discount: parseFloat(order.promo_total),
        products: order.line_items.map((lineItem) => ({
          name: lineItem.product_name,
          id: lineItem.product_id,
          price: parseFloat(lineItem.base_price ?? '0'),
          variant: lineItem.variant_sku,
          quantity: lineItem.quantity,
          subTotal: lineItem.quantity * parseFloat(lineItem.base_price ?? '0'),
          is_subscription: lineItem.is_subscription,
          subscription_frequency: lineItem.subscription_frequency,
        })) as any,
        variant_skus: order.line_items.map(
          (lineItem) => lineItem.variant_sku ?? '',
        ),
        userId: order.user_id,
        shipping_method_id: shippingInfo?.id ?? '',
        shipping_type: shippingInfo?.name ?? '',
        shipping_cost: shippingInfo?.fee ?? 0,
        payment_method: payload.paymentMethod,
      },
    });
  };

  export const trackPdpPageView = (payload: PDPPageViewPayload) => {
    gtm.track({
      event: 'pdpPageLoad',
      eventId: payload.eventId,
      product: {
        id: payload.productId,
        name: payload.productName,
        price: payload.productPrice,
      },
    });

    freshpaint.track('Pdp Page View', {
      ...payload,
      // Note: required by GA4
      items: [
        {
          id: payload.productId,
          name: payload.productName,
          price: payload.productPrice,
        },
      ],
      insert_id: payload.eventId,
    });
  };

  export const trackMembershipPurchase = (
    payload: MembershipPurchasePayload,
  ) => {
    const { userId, userEmail, program } = payload;

    const hashedEmail = hashSha256(userEmail) || null;
    const eventId = uuidv4();

    gtm.track({
      event: 'Purchase',
      hashedEmail,
      userEmail,
      userId,
      program,
      eventId,
    });

    const freshPaintEvent = MEMBERSHIP_TRACKING_SLUGS[program] ?? program;

    freshpaint.track(`${freshPaintEvent} Purchase`, {
      hashedEmail,
      userId,
      program,
      insert_id: eventId,
      email: userEmail,
    });
  };
}
